import { Formik, Form } from 'formik'
import { Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as LogoImage } from '../assets/logo.svg'
import { PageWrapper, Input, Button } from '../components'
import { useFetch, useQuery } from '../hooks'

interface FormValues {
  confirmationCode: string
}

const initialValues: FormValues = {
  confirmationCode: '',
}

const validationSchema = Yup.object().shape({
  confirmationCode: Yup.string().trim().required('Required').length(6, 'Code must contain 6 digits'),
})

const FormStyled = styled(Form)`
  height: 100%;
  margin-top: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`

const LogoImageStyled = styled(LogoImage)`
  width: 120px;
  height: 28px;
  margin: 12px 12px 0 auto;
  flex-shrink: 0;
  flex-grow: 0;
`

export const ConfirmationPage = () => {
  const navigate = useNavigate()
  const { fetchData, isError } = useFetch<null>('/auth/sign-up/confirm')
  const query = useQuery()
  const email = decodeURIComponent(query.get('email') || '')

  const handleSuccessConfirmation = () => navigate('/sign-in')

  return email ? (
    <PageWrapper>
      <LogoImageStyled />
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues) => {
          fetchData({
            body: { email, confirmationCode: values.confirmationCode },
            onSuccess: handleSuccessConfirmation,
          })
        }}
        validationSchema={validationSchema}
      >
        <FormStyled>
          <Input
            type='text'
            name='confirmationCode'
            label='Check your email and enter confirmation code'
            maxLength='6'
            size='6'
          />
          <Button type='submit'>ok</Button>
        </FormStyled>
      </Formik>
    </PageWrapper>
  ) : (
    <Navigate to='/sign-up' />
  )
}
