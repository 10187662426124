import { useWeb3Modal } from '@web3modal/wagmi/react'
import jwtDecode from 'jwt-decode'
import styled from 'styled-components'
import { formatUnits } from 'viem'
import { useAccount, useNetwork, useSwitchNetwork, useContractRead } from 'wagmi'
import { polygonMumbai } from 'wagmi/chains'

import { ReactComponent as LogoSmallImage } from '../../assets/logo-small.svg'
import ytemLogoSrc from '../../assets/YTEM.png'
import { tokenContract } from '../../contracts'
import { truncateDecimals } from '../../helpers'
import { Button } from '../button'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 7px;

  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

const Avatar = styled(LogoSmallImage)`
  display: block;
  width: 40px;
  height: 40px;
  margin-top: -3px;
`

const ButtonRight = styled(Button)`
  margin: 0 0 0 auto;
`

const BalanceWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Header = () => {
  const { open } = useWeb3Modal()
  const { isConnected, address } = useAccount()
  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()

  const token = localStorage.getItem('system256-accessToken') || localStorage.getItem('system256-refreshToken')
  const parsedToken = token && jwtDecode<{ username: string }>(token)
  const username = (parsedToken && parsedToken.username) || ''

  const { data: accountBalance } = useContractRead({
    address: process.env.REACT_APP_YTEM_TESTNET_CONTRACT_ADDRESS as `0x${string}`,
    abi: tokenContract,
    functionName: 'balanceOf',
    args: [address as `0x${string}`],
    watch: true,
    enabled: !!address,
  })

  const accountBalanceParsed = accountBalance ? formatUnits(accountBalance, 18) : '0'

  return (
    <Wrapper>
      {!isConnected ? (
        <Button onClick={() => open()}>connect wallet</Button>
      ) : (
        <>
          <UserInfo onClick={() => open()}>
            <Avatar />
            <span>{username}</span>
          </UserInfo>
          {chain?.unsupported ? (
            <ButtonRight onClick={() => switchNetwork?.(polygonMumbai.id)} width={200}>
              Change network
            </ButtonRight>
          ) : (
            <BalanceWrapper>
              {truncateDecimals(+accountBalanceParsed, 4)}
              <img src={ytemLogoSrc} alt='YTEM logo' width='25px' height='25px' style={{ marginTop: '-3px' }} />
            </BalanceWrapper>
          )}
        </>
      )}
    </Wrapper>
  )
}
