import { useState, useRef, useEffect, WheelEvent, TouchEvent, KeyboardEvent, FocusEvent } from 'react'

import styles from './style.module.scss'

import ytemLogoSrc from '../../assets/YTEM.png'
import { useBetContext } from '../../context'

const OPTIONS = [
  { id: 0, value: '1.0' },
  { id: 1, value: '1.5' },
  { id: 2, value: '2.0' },
  { id: 3, value: '2.5' },
  { id: 4, value: '3.0' },
  { id: 5, value: '3.5' },
  { id: 6, value: '4.0' },
  { id: 7, value: '4.5' },
  { id: 8, value: '5.0' },
  { id: 9, value: '5.5' },
  { id: 10, value: '6.0' },
  { id: 11, value: '6.5' },
  { id: 12, value: '7.0' },
  { id: 13, value: '7.5' },
  { id: 14, value: '8.0' },
  { id: 15, value: '8.5' },
  { id: 16, value: '9.0' },
  { id: 17, value: '9.5' },
  { id: 18, value: '10.0' },
]

const CurrencySelector = ({ hidden }: { hidden: boolean }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState(Math.round((OPTIONS.length - 1) / 2))
  const [progress, setProgress] = useState<number>(selectedItem * 10)
  const [startY, setStartY] = useState<number>(0)
  const [isDrag, setIsDrag] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const trackRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { setAmountToBet } = useBetContext()

  useEffect(() => {
    if (isOpened) {
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.touchAction = 'none'
      document.documentElement.style.overscrollBehavior = 'contain'
    } else {
      document.documentElement.style.overflow = ''
      document.documentElement.style.touchAction = ''
      document.documentElement.style.overscrollBehavior = ''
    }
  }, [isOpened])

  useEffect(() => {
    const newSelectedItem = Math.floor(progress / 10)
    setSelectedItem(newSelectedItem)
    setAmountToBet(parseFloat(OPTIONS[newSelectedItem].value))
  }, [progress])

  useEffect(() => {
    if (!inputValue) return

    const newSelectedItem = OPTIONS.find((option) => option.value.includes(inputValue))

    newSelectedItem && setProgress(newSelectedItem.id * 10)
  }, [inputValue])

  const handleWheel = (e: WheelEvent) => {
    setInputValue('')
    inputRef.current?.blur()
    trackRef.current?.focus()
    selectedItem === 20 && OPTIONS[20] && OPTIONS.pop()

    const wheelProgress = e.deltaY * 0.5
    let newProgress = Math.round(progress + wheelProgress)

    newProgress = Math.floor(Math.max(0, Math.min(newProgress, (OPTIONS.length - 1) * 10)))
    setProgress(newProgress)
  }

  const handleTouchStart = (e: TouchEvent) => {
    setIsDrag(true)
    setStartY(e.touches[0].clientY)
  }

  const handleTouchEnd = (e: TouchEvent) => {
    setIsDrag(false)
  }

  const handleTouchMove = (e: TouchEvent) => {
    setInputValue('')
    inputRef.current?.blur()
    trackRef.current?.focus()
    selectedItem === 20 && OPTIONS[20] && OPTIONS.pop()

    if (!isDrag) return

    const y = (e.touches && e.touches[0].clientY) || 0
    const mouseProgress = (y - startY) * -0.5
    let newProgress = Math.round(progress + mouseProgress)

    newProgress = Math.floor(Math.max(0, Math.min(newProgress, (OPTIONS.length - 1) * 10)))

    setStartY(y)
    setProgress(newProgress)
  }

  const handlePressEnter = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return
    ;(e.target as HTMLInputElement).blur()

    const result = OPTIONS.find((option) => option.value.includes(inputValue))

    if (result) return

    OPTIONS[OPTIONS.length - 1].id === 19 && OPTIONS.pop()
    OPTIONS.push({ id: 19, value: inputValue })
    setProgress(190)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const result = OPTIONS.find((option) => option.value.includes(inputValue))

    if (result) return

    OPTIONS[OPTIONS.length - 1].id === 19 && OPTIONS.pop()
    OPTIONS.push({ id: 19, value: inputValue })
    setProgress(190)
  }

  return (
    <div className={styles['wrapper']} hidden={hidden}>
      <div
        className={`${styles['track']} ${isOpened && styles['track_active']}`}
        ref={trackRef}
        onWheel={handleWheel}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onFocus={() => setIsOpened(true)}
        onBlur={() => setIsOpened(false)}
        tabIndex={0}
      >
        {OPTIONS.map((option) => (
          <div
            key={option.id}
            className={`${styles['item']}`}
            onClick={() => {
              selectedItem === 20 && OPTIONS[20] && OPTIONS.pop()
              setProgress(option.id * 10)
            }}
            data-active={option.id === selectedItem}
            style={
              {
                '--active': option.id - selectedItem,
              } as React.CSSProperties
            }
          >
            {option.value}
          </div>
        ))}
        <input
          className={styles['input']}
          ref={inputRef}
          type='text'
          value={inputValue}
          onChange={(e) => {
            if (/^[0-9]*\.?[0-9]*$/.test(e.target.value) === false) return
            setInputValue(e.target.value && parseFloat(e.target.value).toString())
          }}
          onFocus={() => {
            setInputValue(OPTIONS.find((option) => option.id === selectedItem)?.value || '')
          }}
          // onBlur={() => setInputValue('')}
          onBlur={handleBlur}
          onKeyDown={handlePressEnter}
        />
        <div className={styles['background']}></div>
        <img className={styles['token-logo']} src={ytemLogoSrc} alt='YTEM logo' />
      </div>
    </div>
  )
}

export default CurrencySelector
