import jwtDecode from 'jwt-decode'
import { useState, useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'

interface JwtTokenDecoded {
  exp: number
  iat: number
  userId: string
}

export const PublicRoute = () => {
  const [isRedirect, setIsRedirect] = useState<boolean>(false)

  useEffect(() => {
    const accessToken = localStorage.getItem('system256-accessToken')
    const accessTokenDecoded: JwtTokenDecoded | undefined = accessToken ? jwtDecode(accessToken) : undefined

    if (accessTokenDecoded && Date.now() < accessTokenDecoded.exp * 1000) {
      setIsRedirect(true)
      return
    }
    localStorage.removeItem('system256-accessToken')

    const refreshToken = localStorage.getItem('system256-refreshToken')
    const refreshTokenDecoded: JwtTokenDecoded | undefined = refreshToken ? jwtDecode(refreshToken) : undefined

    if (refreshTokenDecoded && Date.now() < refreshTokenDecoded.exp * 1000) {
      setIsRedirect(true)
      return
    }
    localStorage.removeItem('system256-refreshToken')
  }, [])

  return isRedirect ? <Navigate to='/' /> : <Outlet />
}
