import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { ToastContainer } from 'react-toastify'
import { WagmiConfig } from 'wagmi'
import { polygon, polygonMumbai } from 'wagmi/chains'

import 'react-toastify/dist/ReactToastify.css'
import { AppRoutes } from './routes'

import 'normalize.css'
import './App.scss'

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string

const chains = [process.env.REACT_APP_API_URL === 'https://api.appsystem256.com/api' ? polygon : polygonMumbai]

const wagmiConfig = defaultWagmiConfig({ chains, projectId })

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  includeWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'],
  themeVariables: {
    '--w3m-accent': '#FFF800',
  },
})

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <AppRoutes />
      </WagmiConfig>

      <ToastContainer
        position='top-center'
        theme='dark'
        progressStyle={{
          background: '#FFF800',
        }}
      />
    </>
  )
}

export default App
