import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as LogoImage } from '../assets/logo.svg'
import { PageWrapper, Input, Button } from '../components'
import { useFetch } from '../hooks'

interface FormValues {
  username: string
  email: string
  password: string
  confirmPassword: string
}

const initialValues: FormValues = {
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const validationSchema = Yup.object().shape({
  username: Yup.string().trim().required('Required'),
  email: Yup.string().trim().email('Invalid email').required('Required'),
  password: Yup.string()
    .trim()
    .required('Required')
    .min(8, 'Password shoud contain at least 8 chars')
    .matches(/\d/g, 'Password shoud contain at least 1 digit')
    .matches(/[A-Z]/g, 'Password shoud contain at least 1 uppercase letter')
    .matches(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, 'Password shoud contain at least 1 special char'),
  confirmPassword: Yup.string()
    .trim()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
})

const FormStyled = styled(Form)`
  height: 100%;
  margin-top: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`

const LogoImageStyled = styled(LogoImage)`
  width: 120px;
  height: 28px;
  margin: 12px 12px 0 auto;
  flex-shrink: 0;
  flex-grow: 0;
`

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  gap: 13px;
`

export const SignUp = () => {
  const navigate = useNavigate()
  const { fetchData, isError } = useFetch<null>('/auth/sign-up')

  const handleSuccessConfirm = (email: string) => navigate(`/confirm?email=${encodeURIComponent(email)}`)

  return (
    <PageWrapper>
      <LogoImageStyled />
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues) => {
          fetchData({ body: values, onSuccess: () => handleSuccessConfirm(values.email) })
        }}
        validationSchema={validationSchema}
      >
        <FormStyled>
          <Input type='text' name='username' label='nickname' />
          <Input type='email' name='email' label='email' />
          <Input type='password' name='password' label='password' autoComplete='new-password' />
          <Input type='password' name='confirmPassword' label='confirm password' />
          <ButtonsWrapper>
            <Button type='submit'>register</Button>
            <Button type='button' onClick={() => navigate('/sign-in')}>
              sign in
            </Button>
          </ButtonsWrapper>
        </FormStyled>
      </Formik>
    </PageWrapper>
  )
}
