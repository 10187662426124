import { Formik, Form } from 'formik'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as LogoImage } from '../assets/logo.svg'
import { PageWrapper, Input, Button } from '../components'
import { useFetch } from '../hooks'

interface FormValues {
  email: string
  password: string
}

interface LoginResponse {
  accessToken: string
  refreshToken: string
}

const initialValues: FormValues = {
  email: '',
  password: '',
}

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email('Invalid email').required('Required'),
  password: Yup.string().trim().required('Required'),
})

const FormStyled = styled(Form)`
  height: 100%;
  margin-top: 179px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  gap: 13px;
`

const LinkStyled = styled.div`
  width: calc(50% - 13px);
  text-align: center;
  margin: -10px auto 0 0;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  cursor: pointer;
`

export const SignIn = () => {
  const navigate = useNavigate()
  const { fetchData, isError } = useFetch<LoginResponse>('/auth/sign-in')

  const onSuccessLogin = (response: LoginResponse) => {
    localStorage.setItem('system256-accessToken', response.accessToken)
    localStorage.setItem('system256-refreshToken', response.refreshToken)
    navigate('/')
  }

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues) =>
          fetchData({
            body: values,
            onSuccess: onSuccessLogin,
          })
        }
        validationSchema={validationSchema}
      >
        <FormStyled>
          <LogoImage />
          <Input type='email' name='email' label='email' />
          <Input type='password' name='password' label='password' />
          <ButtonsWrapper>
            <Button type='submit'>log in</Button>
            <Button type='button' onClick={() => navigate('/sign-up')}>
              registration
            </Button>
          </ButtonsWrapper>
          <LinkStyled onClick={() => navigate('/forgot-password')}>Forgot password?</LinkStyled>
        </FormStyled>
      </Formik>
    </PageWrapper>
  )
}
