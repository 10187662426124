import { useTimer } from 'react-timer-hook'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
`

const Title = styled.span`
  display: block;
  font-size: 25px;
  color: #000000;
`

const TimerText = styled.span<{ isDays: boolean }>`
  display: block;
  margin-top: -10px;
  font-size: 110px;

  ${(props) =>
    props.isDays &&
    `
    font-size: 58px;
    margin-top: -5px;
  `}

  color: #000000;
`

const formatNumber = (number: number) => (number.toString().length < 2 ? `0${number}` : number)

const convertWord = (word: 'day' | 'hour', quantity: number) => (quantity > 1 ? word + 's' : word)

export const Timer = ({
  expiryTimestamp,
  getDuelStatus,
  isRoundActive,
}: {
  expiryTimestamp: Date
  getDuelStatus?: () => Promise<void>
  isRoundActive: boolean
}) => {
  const { seconds, minutes, hours, restart, days } = useTimer({
    expiryTimestamp: expiryTimestamp,
    onExpire: () => handleExpire(),
  })

  const handleExpire = async () => {
    await getDuelStatus?.()
    restart(expiryTimestamp)
  }

  return (
    <Wrapper>
      <Title>{isRoundActive ? 'Time left' : 'Next round starts in'}</Title>
      <TimerText isDays={!!days}>
        {days
          ? `${days} ${convertWord('day', days)}, ${hours} ${convertWord('hour', hours)}`
          : `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`}
      </TimerText>
    </Wrapper>
  )
}
