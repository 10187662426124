import { waitForTransaction } from '@wagmi/core'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { parseUnits, formatUnits } from 'viem'
import { usePrepareContractWrite, useContractWrite, useContractRead, useAccount } from 'wagmi'

import { HashLink } from '../components'
import { duelsContract, tokenContract } from '../contracts'

export const useVote = (amountToBet: number) => {
  const [isLoading, setIsLoading] = useState(false)
  const { address } = useAccount()

  const { refetch: getAllowance } = useContractRead({
    address: process.env.REACT_APP_YTEM_TESTNET_CONTRACT_ADDRESS as `0x${string}`,
    abi: tokenContract,
    functionName: 'allowance',
    args: [address as `0x${string}`, process.env.REACT_APP_DUELS_CONTRACT_ADDRESS as `0x${string}`],
    select: (data) => parseFloat(formatUnits(data, 18)),
    enabled: false,
  })

  const { config: allowanceConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_YTEM_TESTNET_CONTRACT_ADDRESS as `0x${string}`,
    abi: tokenContract,
    functionName: 'approve',
    args: [process.env.REACT_APP_DUELS_CONTRACT_ADDRESS as `0x${string}`, parseUnits(amountToBet.toString(), 18)],
  })

  const { writeAsync: approveTokens } = useContractWrite(allowanceConfig)

  const { writeAsync: makeVote } = useContractWrite({
    address: process.env.REACT_APP_DUELS_CONTRACT_ADDRESS as `0x${string}`,
    abi: duelsContract,
    functionName: 'voteForNft',
  })

  const handleApprove = async () => {
    try {
      const { data: allowance, isSuccess: isAllowanceSuccess } = await getAllowance()

      if (isAllowanceSuccess && allowance >= amountToBet) return true

      const approveResponse = await approveTokens?.()

      if (!approveResponse?.hash) return false

      const waitResponse = await waitForTransaction({
        hash: approveResponse.hash,
      })

      if (waitResponse.status === 'success') return true

      return false
    } catch (error: any) {
      toast.warning(typeof error === 'string' ? error : error.shortMessage)
      return false
    }
  }

  const handleVote = async (option: number) => {
    setIsLoading(true)

    try {
      const isApproveSuccess = await handleApprove()

      if (!isApproveSuccess) return

      const voteResponse = await makeVote?.({
        args: [option, parseUnits(amountToBet.toString(), 18)],
      })

      if (!voteResponse?.hash) return

      const waitResponse = await waitForTransaction({
        hash: voteResponse.hash,
      })

      if (waitResponse.status === 'success') {
        setIsLoading(false)

        toast.success(
          <span>
            You successfuly voted <HashLink hash={waitResponse.transactionHash} />
          </span>,
        )
        return waitResponse.transactionHash
      }

      return false
    } catch (error: any) {
      toast.warning(typeof error === 'string' ? error : error.shortMessage)
      setIsLoading(false)
    }
  }

  return { handleVote, isLoading }
}
