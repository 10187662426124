import { Formik, Form } from 'formik'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as LogoImage } from '../assets/logo.svg'
import { PageWrapper, Input, Button } from '../components'
import { useFetch } from '../hooks'

interface FormValues {
  email: string
  password: string
  confirmPassword: string
}

const initialValues: FormValues = {
  email: '',
  password: '',
  confirmPassword: '',
}

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email('Invalid email').required('Required'),
  password: Yup.string()
    .trim()
    .required('Required')
    .min(8, 'Password shoud contain at least 8 chars')
    .matches(/\d/g, 'Password shoud contain at least 1 digit')
    .matches(/[A-Z]/g, 'Password shoud contain at least 1 uppercase letter')
    .matches(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, 'Password shoud contain at least 1 special char'),
  confirmPassword: Yup.string()
    .trim()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
})

const FormStyled = styled(Form)`
  height: 100%;
  margin-top: 203px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`

const LogoImageStyled = styled(LogoImage)`
  width: 120px;
  height: 28px;
  margin: 12px 12px 0 auto;
  flex-shrink: 0;
  flex-grow: 0;
`

export const NewPassword = () => {
  const navigate = useNavigate()
  const { code } = useParams()
  const { fetchData, isError } = useFetch<null>(`/auth/reset-password/${code}`)

  const handleSuccess = () => navigate('/sign-in')

  return code ? (
    <PageWrapper>
      <LogoImageStyled />
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues) =>
          fetchData({
            body: values,
            onSuccess: handleSuccess,
          })
        }
        validationSchema={validationSchema}
      >
        <FormStyled>
          <Input type='email' name='email' label='email' />
          <Input type='password' name='password' label='new password' autoComplete='new-password' />
          <Input type='password' name='confirmPassword' label='confirm password' />
          <Button type='submit'>submit</Button>
        </FormStyled>
      </Formik>
    </PageWrapper>
  ) : (
    <Navigate to='/login' />
  )
}
