import styled from 'styled-components'

import styles from './style.module.scss'

import { useRefund } from '../../hooks'

import { Button } from '../button'

const ModalBorder = styled.div`
  background: linear-gradient(90deg, #ffffff 0%, #fff367 58.46%, #ff9705 100%);
  border-radius: 12px;
  padding: 1px;
  cursor: pointer;
  margin-top: 140px;
`

const RefundModal = () => {
  const { availableToRefund, isRefundLoading, refund, show } = useRefund()

  return availableToRefund && show ? (
    <div className={styles['wrapper']}>
      <ModalBorder>
        <div className={styles['modal']}>
          <div className={styles['modal__gradient']}>
            <p className={styles['modal__title']}>Sorry</p>
            <p className={styles['modal__text']}>
              Unfortunately, the voting did not take place due to an insufficient number of votes. In order to return
              your bet click Return.
            </p>
            <Button
              className={styles['modal__button']}
              isLoading={isRefundLoading}
              disabled={isRefundLoading}
              onClick={refund}
            >
              Return
            </Button>
          </div>
        </div>
      </ModalBorder>
    </div>
  ) : null
}

export default RefundModal
