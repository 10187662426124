import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useAccount } from 'wagmi'

import { Button } from '../button'
import { Notification } from '../notification'

const DailyClaim = styled(Button)`
  font-size: 20px;
  max-width: 376px;
  min-width: 320px;
  width: 100%;
  margin-bottom: 10px;
`

const LinkStyled = styled.a`
  display: block;
  color: #fff;
`

export const ButtonDailyClaim = () => {
  const navigate = useNavigate()
  const { isConnected, address } = useAccount()
  const [isShow, setIsShow] = useState<boolean>(false)
  const [transactionHash, setTransactionHash] = useState<string>('')
  const [fetching, setFetching] = useState<boolean>(false)

  useEffect(() => {
    const clickedTimeDailyClaim = localStorage.getItem('timeDailyClaim')

    if (!clickedTimeDailyClaim) {
      setIsShow(true)
      return
    }

    const clickedDate = new Date(+clickedTimeDailyClaim)
    clickedDate.setHours(clickedDate.getHours() + 24)

    if (clickedDate.getTime() <= Date.now()) {
      setIsShow(true)
    }
  }, [])

  const getDailyClaim = async () => {
    if (fetching) return
    setFetching(true)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/faucet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            localStorage.getItem('system256-accessToken') || localStorage.getItem('system256-refreshToken')
          }`,
        },
        body: JSON.stringify({ address }),
      })

      const result = await response.json()

      if (result.statusCode === 401) {
        localStorage.removeItem('system256-accessToken')
        localStorage.removeItem('system256-refreshToken')
        navigate('/sign-in')
      }

      const timeClicked = Date.now()

      if (response.ok) {
        localStorage.setItem('timeDailyClaim', String(timeClicked))
        setTransactionHash(result.data.hash)
        setIsShow(false)
      }
    } catch (error) {
      setFetching(false)
    }
  }

  return isConnected && isShow ? (
    <>
      <DailyClaim onClick={getDailyClaim}>generate 100 YTEM to your wallet in 24 hours</DailyClaim>
    </>
  ) : transactionHash ? (
    <Notification
      text={
        <span>
          Tokens successfully claimed{' '}
          <LinkStyled href={`https://mumbai.polygonscan.com/tx/${transactionHash}`} target='__black'>
            View in explorer
          </LinkStyled>
        </span>
      }
      saveToStorage={false}
    />
  ) : (
    <></>
  )
}
