import * as Toast from '@radix-ui/react-toast'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { NotificationProps } from './types'

const ToastViewport = styled(Toast.Viewport)`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  width: 100%;
  max-width: 390px;
  min-width: 320px;
  padding: 0 7px;
  z-index: 2;
`

const ToastRoot = styled(Toast.Root)`
  width: 100%;
  background: #1c1c1b;
  padding: 20px 30px;
  border-radius: 17px;
  border: solid 1px #fdf602;
  color: #fff;
  text-align: center;
  font-size: 20px;

  button {
    all: unset;
    position: absolute;
    top: 10px;
    right: 20px;
    color: #fff;
    cursor: pointer;
  }
`

export const Notification = ({ text, saveToStorage = true }: NotificationProps) => {
  const [open, setOpen] = useState(true)
  const timerRef = useRef(0)

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  return (
    <Toast.Provider swipeDirection='right' duration={3000}>
      <ToastRoot
        className='ToastRoot'
        open={open}
        onOpenChange={(state) => {
          saveToStorage && localStorage.setItem('notificationShowed', 'true')
          setOpen(state)
        }}
      >
        <Toast.Title className='ToastTitle'>
          {text} <button onClick={() => setOpen(false)}>✕</button>
        </Toast.Title>
      </ToastRoot>
      <ToastViewport className='ToastViewport' />
    </Toast.Provider>
  )
}
