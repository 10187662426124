import styled from 'styled-components'

import { PageWrapperProps } from './types'

const Wrapper = styled.div`
  max-width: 390px;
  min-width: 320px;
  width: 100%;
  height: 792px;
  padding: 0 7px;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 1px #fdf602;
  border-bottom: 0;
  border-radius: 17px 17px 0 0;
  background: linear-gradient(180deg, #f8a900 51.56%, #ffda01 81.45%, #ffee80 100%);
  overflow: hidden;

  & > * {
    position: relative;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    aspect-ratio: 1;
    background: black;
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: translateY(50%);
    border-radius: 50%;
    box-shadow: 0px -220px 80px 400px black;
  }
`

export const PageWrapper = ({ children }: PageWrapperProps) => {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  )
}
