// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/helvetica-neue-55);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Bebas Neue', sans-serif;
  box-sizing: border-box; }

#root,
body,
html {
  margin: 0;
  padding: 0;
  min-height: 100dvh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  --background-color: #1C1C1B; }

body {
  background-color: var(--background-color); }

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAGA;EACE,qCAAqC;EACrC,sBAAsB,EAAA;;AAGxB;;;EAGE,SAAS;EACT,UAAU;EACV,kBAAkB;EAElB,mCAAmC;EACnC,kCAAkC,EAAA;;AAGpC;EACE,2BAAmB,EAAA;;AAGrB;EACE,yCAAyC,EAAA;;AAG3C;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB,EAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');\n@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');\n\n* {\n  font-family: 'Bebas Neue', sans-serif;\n  box-sizing: border-box;\n}\n\n#root,\nbody,\nhtml {\n  margin: 0;\n  padding: 0;\n  min-height: 100dvh;\n\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nhtml {\n  --background-color: #1C1C1B;\n}\n\nbody {\n  background-color: var(--background-color);\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 10px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
