import styled from 'styled-components'

import { Button } from '..'

import { Timer } from '..'
import chatIconPath from '../../assets/support_button.png'
import { useBetContext } from '../../context'
import { truncateDecimals } from '../../helpers'
import { useClaim } from '../../hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  width: calc(100% - 18px);
  height: 315px;
  margin: 7px auto 0;
  padding-top: 2px;
  border-radius: 22px 22px 0 0;
  background: linear-gradient(180deg, #ffffff 27.08%, rgba(255, 255, 255, 0) 100%);
  margin-top: 30px;
`

const FooterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-bottom: 10px;
`

const ClaimWrapper = styled.div`
  width: 100%;
`

const SupportWrapper = styled.div`
  width: 40px;
  height: 28px;
  cursor: pointer;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const BettingControls = () => {
  const { timerEnd, getDuelStatus, isDuelActive } = useBetContext()

  const { availableToClaim, isClaimLoading, claim } = useClaim()

  return (
    <Wrapper>
      {timerEnd && <Timer expiryTimestamp={timerEnd} getDuelStatus={getDuelStatus} isRoundActive={isDuelActive} />}
      <FooterWrap>
        <ClaimWrapper>
          <Button disabled={!availableToClaim || isClaimLoading} isLoading={isClaimLoading} onClick={claim}>
            {availableToClaim ? `Claim ${truncateDecimals(+availableToClaim, 4)} YTEM` : 'Play to earn rewards'}
          </Button>
        </ClaimWrapper>
        <SupportWrapper>
          <a href='https://t.me/hiforufo' target='_blank' rel='noreferrer'>
            <img src={chatIconPath} alt='Link to support' />
          </a>
        </SupportWrapper>
      </FooterWrap>
    </Wrapper>
  )
}
