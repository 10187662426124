import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useState } from 'react'
import styled from 'styled-components'
import { useAccount } from 'wagmi'

import { OptionProps } from './types'

import { useBetContext } from '../../context'
import { Button } from '../button'

const HiddenStyled = styled.div`
  ${({ hidden }) =>
    hidden &&
    `
    visibility: hidden;
    opacity: 0;
  `}

  transition: visibility 0.3s ease, 
              opacity 0.3s ease,
              transform 0.3s ease;
`

const Wrapper = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
`

const ImageStyled = styled(HiddenStyled)<{ isThisNftSelected: boolean; directionReversed: boolean }>`
  display: block;
  /* width: 185px; */
  width: 165px;
  height: 182px;
  object-fit: contain;
  /* border-radius: 18px; */
  margin: 0 auto;
  transform-origin: top center;
  position: relative;

  ${({ isThisNftSelected, directionReversed }) =>
    isThisNftSelected &&
    `
    transform: translateX(${directionReversed ? 'calc(-50% - 2px)' : 'calc(50% + 2px)'}) scale(1.85);
    z-index: 10;
  `}
`

const NftName = styled(HiddenStyled)`
  display: block;
  margin-top: 4px;
  font-size: 26px;
  line-height: 31px;
  color: #fff;
  flex-grow: 1;
`

const VotedAmount = styled(HiddenStyled)`
  display: block;
  color: #fff;
  margin-top: 5px;
  min-height: 20px;
`

const OkButton = styled(HiddenStyled)<{ show: boolean }>`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 423px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  ${({ show }) =>
    show &&
    `
    visibility: visible;
    opacity: 1;
  `}
  transition: visibility 0s ease , 
              opacity 0s ease,
              transform 0s ease !important;
`

export const Option = ({ data, handleBet, isZoomed, setIsZoomed, isLoading }: OptionProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const { isDuelActive, duelWinner, votedAmount, prevDataRound } = useBetContext()
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()

  const isImageHidden =
    (isZoomed && !isActive) ||
    (prevDataRound?.winner !== undefined &&
      prevDataRound?.winner !== data.id &&
      localStorage.getItem('showLastIcon') === 'true')

  return (
    <Wrapper>
      <ImageStyled
        as='img'
        src={data.src}
        alt=''
        onClick={() => {
          if (
            duelWinner?.winner !== undefined ||
            (localStorage.getItem('showLastIcon') === 'true' && prevDataRound?.winner !== undefined)
          )
            return

          setIsZoomed((prevState) => !prevState)
          setIsActive((prevState) => !prevState)
        }}
        isThisNftSelected={isActive || (duelWinner?.winner === data.id && !isDuelActive)}
        directionReversed={data.id === 1}
        hidden={isImageHidden || (duelWinner?.winner !== data.id && !isDuelActive && duelWinner?.winner !== undefined)}
      />
      <NftName as='span' hidden={!isDuelActive || isZoomed}>
        {data.name}
      </NftName>
      <HiddenStyled
        as={Button}
        width={150}
        onClick={() => (isConnected ? handleBet(data) : open())}
        hidden={!isDuelActive || isZoomed}
        disabled={isLoading}
        isLoading={isLoading}
      >
        {votedAmount[data.id] > 0 ? 'Add tokens' : 'bet'}
      </HiddenStyled>
      <VotedAmount as='span' hidden={!isDuelActive || isZoomed}>
        {votedAmount[data.id] ? `${votedAmount[data.id]} YTEM ADDED` : ''}
      </VotedAmount>
      <OkButton
        as={Button}
        width={100}
        onClick={() => localStorage.setItem('showLastIcon', 'false')}
        show={localStorage.getItem('showLastIcon') === 'true' && prevDataRound?.winner !== undefined && isDuelActive}
      >
        OK
      </OkButton>
    </Wrapper>
  )
}
