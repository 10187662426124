import styled from 'styled-components'

import { Header, BettingOptions, BettingControls, ButtonDailyClaim, PageWrapper } from '../components'
import RefundModal from '../components/refundModal/RefundModal'
import { WrapperForPrevRound } from '../components/wrapperForPrevRound'

import { BetContextProvider } from '../context'

const BettingWrapper = styled.div`
  min-height: 450px;
  height: max-content;
`

export const BettingPage = () => (
  <BetContextProvider>
    {process.env.REACT_APP_API_URL !== 'https://api.appsystem256.com/api' && <ButtonDailyClaim />}
    <PageWrapper>
      <RefundModal />
      <WrapperForPrevRound />
      <BettingWrapper>
        <Header />
        <BettingOptions />
      </BettingWrapper>
      <BettingControls />
    </PageWrapper>
  </BetContextProvider>
)
