import styled from 'styled-components'

const StyledLink = styled.a`
  color: #fff;
`

export const HashLink = ({ hash }: { hash: `0x${string}` }) => (
  <StyledLink href={`https://polygonscan.com/tx/${hash}`} target='_blank' rel='noreferrer'>
    Hash
  </StyledLink>
)
