import styled from 'styled-components'

const LoaderStyled = styled.div`
  border: 5px solid #fff;
  border-top: 5px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Loader = () => {
  return <LoaderStyled />
}
