import { Field, FieldAttributes, useField } from 'formik'
import { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as PasswordIcon } from '../../assets/eye_icon.svg'

const Label = styled.div`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const FieldWrapper = styled.div`
  position: relative;
  width: 334px;
  height: 46px;
  border-radius: 12px;
  background: linear-gradient(90.84deg, #fcd506 0%, rgba(246, 239, 199, 0.4) 80.73%, rgba(245, 245, 245, 0.1) 100%);
  overflow: hidden;
  padding: 1px 1px 2px;
`

const FieldStyled = styled(Field)<{ $isPassword: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 12px;
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #fcfafa 74.48%);
  border: none;
  box-shadow: 0 1px 0 0 #020202;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.3px;

  &:focus {
    outline: none;
  }

  ${({ $isPassword }) => $isPassword && `padding-right: 42px;`}
`

const ErrorMessage = styled.span<{ visible: boolean }>`
  color: #ff2121;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: none;
  ${({ visible }) => visible && `display: block;`}
`

const PasswordIconStyled = styled.div<{ showPassword: boolean }>`
  position: absolute;
  top: 50%;
  right: 13px;
  cursor: pointer;
  transition: transform 0.3s ease;

  ${({ showPassword }) => {
    return showPassword ? 'transform: translateY(-50%) rotateX(180deg);' : 'transform: translateY(-50%);'
  }}

  svg {
    display: block;
  }
`

export const Input = ({ children, label, ...rest }: FieldAttributes<any>) => {
  const isPassword = rest.type === 'password'
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [, meta] = useField(rest.name)

  return (
    <div>
      <Label>{label}</Label>
      <FieldWrapper>
        {children ? (
          <FieldStyled {...rest} type={isPassword && showPassword ? 'text' : rest.type} $isPassword={isPassword}>
            {children}
          </FieldStyled>
        ) : (
          <FieldStyled {...rest} type={isPassword && showPassword ? 'text' : rest.type} $isPassword={isPassword} />
        )}
        {isPassword && (
          <PasswordIconStyled showPassword={showPassword} onClick={() => setShowPassword((prev) => !prev)}>
            <PasswordIcon />
          </PasswordIconStyled>
        )}
      </FieldWrapper>
      <ErrorMessage visible={meta.touched && !!meta.error}>{meta.error || 'Required'}</ErrorMessage>
    </div>
  )
}
