import jwtDecode from 'jwt-decode'
import { useState, useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'

import { useFetch } from '../hooks'

interface JwtTokenDecoded {
  exp: number
  iat: number
  userId: string
}

export const PrivateRoute = () => {
  const [isRedirect, setIsRedirect] = useState<boolean>(false)

  const { fetchData } = useFetch('/auth/refresh')

  useEffect(() => {
    const accessToken = localStorage.getItem('system256-accessToken')
    const accessTokenDecoded: JwtTokenDecoded | undefined = accessToken ? jwtDecode(accessToken) : undefined

    if (accessTokenDecoded && Date.now() < accessTokenDecoded.exp * 1000) return
    localStorage.removeItem('system256-accessToken')

    const refreshToken = localStorage.getItem('system256-refreshToken')
    const refreshTokenDecoded: JwtTokenDecoded | undefined = refreshToken ? jwtDecode(refreshToken) : undefined

    if (refreshTokenDecoded && Date.now() < refreshTokenDecoded.exp * 1000) {
      fetchData({
        body: { refreshToken },
        onSuccess: (response) => {
          localStorage.setItem('system256-accessToken', response.accessToken)
          localStorage.setItem('system256-refreshToken', response.refreshToken)
        },
      })
      return
    }
    localStorage.removeItem('system256-refreshToken')

    setIsRedirect(true)
  }, [])

  return isRedirect ? <Navigate to={'/sign-in'} /> : <Outlet />
}
