import { createContext, useContext, useState } from 'react'

import { useDuel, UseDuel } from '../hooks'

export const BetContext = createContext<UseDuel | null>(null)

export const BetContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [amountToBet, setAmountToBet] = useState<number>(1)
  const {
    duelStarted,
    duelEnded,
    isDuelActive,
    timerEnd,
    getDuelStatus,
    votedAmount,
    duelWinner,
    currentDuelId,
    prevDataRound,
  } = useDuel()

  return (
    <BetContext.Provider
      value={{
        prevDataRound,
        amountToBet,
        setAmountToBet,
        duelStarted,
        duelEnded,
        isDuelActive,
        timerEnd,
        votedAmount,
        duelWinner,
        getDuelStatus,
        currentDuelId,
      }}
    >
      {children}
    </BetContext.Provider>
  )
}

export const useBetContext = () => {
  const context = useContext(BetContext)

  if (!context) throw new Error('Bet context must be used within a BetContextProvider')

  return context
}
