import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { formatUnits } from 'viem'
import { useContractRead, useAccount, usePrepareContractWrite, useContractWrite } from 'wagmi'

import { HashLink } from '../components'
import { duelsContract } from '../contracts'

const contractReadOptions = {
  address: process.env.REACT_APP_DUELS_CONTRACT_ADDRESS as `0x${string}`,
  abi: duelsContract,
}

export const useRefund = () => {
  const { address } = useAccount()
  const [show, setShow] = useState(true)

  const { data: availableToRefund } = useContractRead({
    ...contractReadOptions,
    account: address,
    functionName: 'availableToRefund',
    args: [address as `0x${string}`],
    select: (data) => parseFloat(formatUnits(data, 18)),
    watch: true,
  })

  const { config: refundConfig } = usePrepareContractWrite({
    ...contractReadOptions,
    abi: duelsContract,
    functionName: 'refund',
    enabled: !!availableToRefund,
  })

  const { isLoading: isRefundLoading, writeAsync: makeRefund } = useContractWrite(refundConfig)

  const refund = async () => {
    try {
      const refundResponse = await makeRefund?.()

      if (refundResponse?.hash)
        toast.success(
          <span>
            Tokens successfuly refunds <HashLink hash={refundResponse.hash} />
          </span>,
        )
      setShow(false)
    } catch (error: any) {
      toast.warning(typeof error === 'string' ? error : error.shortMessage)
      setShow(true)
    }
  }
  useEffect(() => {
    availableToRefund ? setShow(true) : setShow(false)
  }, [availableToRefund])

  return { availableToRefund, isRefundLoading, show, refund }
}
