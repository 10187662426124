import { useState } from 'react'
import styled from 'styled-components'

import { Option } from './Option'
import { OptionType } from './types'

import nftOneSrc from '../../assets/nft-1.jpg'
import nftTwoSrc from '../../assets/nft-2.jpg'
import prevNftOneSrc from '../../assets/prev-nft-1.jpg'
import prevNftTwoSrc from '../../assets/prev-nft-2.jpg'

import { useBetContext } from '../../context'
import { useVote } from '../../hooks'
import AmountPicker from '../amountPicker/AmountPicker'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  margin-top: 17px;
  padding: 0 4px;
`

const VoteInfo = styled.div`
  position: relative;
  z-index: 10;
  color: #fff;
  font-size: 26px;
  display: flex;
  justify-content: space-evenly;
  margin-top: -4px;

  span {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    opacity: 0;
    left: -10px;
    animation: appear 0.5s ease forwards;

    &:nth-of-type(1) {
      animation-delay: 0.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.3s;
    }
    &:nth-of-type(4) {
      animation-delay: 0.4s;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
      left: -10px;
    }
    to {
      opacity: 1;
      left: 0;
    }
  }
`

const OPTIONS: OptionType[] = [
  {
    id: 0,
    src: nftOneSrc,
    coefficient: '1.33',
    name: 'Juan Gris',
    totalVoted: '3.325',
  },
  {
    id: 1,
    src: nftTwoSrc,
    coefficient: '1.67',
    name: 'Pablo Picasso',
    totalVoted: '3.325',
  },
]

export const PREV_OPTIONS: OptionType[] = [
  {
    id: 0,
    src: prevNftOneSrc,
    coefficient: '1.33',
    name: 'Gustav Klimt',
    totalVoted: '3.325',
  },
  {
    id: 1,
    src: prevNftTwoSrc,
    coefficient: '1.67',
    name: 'Egon Schiele',
    totalVoted: '3.325',
  },
]

export const BettingOptions = () => {
  const [selectedNft, setSelectedNft] = useState<OptionType | undefined>(undefined)
  const [isZoomed, setIsZoomed] = useState<boolean>(false)

  const { amountToBet, duelWinner, isDuelActive, prevDataRound } = useBetContext()

  const { handleVote, isLoading } = useVote(amountToBet)

  const handleBet = async (option: OptionType) => {
    try {
      setSelectedNft(option)
      const writeResultHash = await handleVote(option.id)
      localStorage.setItem('hash', writeResultHash || '')
      setSelectedNft(undefined)
    } catch (error) {}
  }

  return (
    <>
      <Wrapper>
        {OPTIONS.map((option) => (
          <Option
            key={option.id}
            data={option}
            handleBet={handleBet}
            isZoomed={isZoomed}
            setIsZoomed={setIsZoomed}
            isLoading={isLoading && selectedNft?.id === option.id}
          />
        ))}
      </Wrapper>
      <AmountPicker hidden={isZoomed || !isDuelActive} />

      {!isDuelActive && duelWinner?.winner !== undefined ? (
        <VoteInfo>Winner with coefficient of {duelWinner?.coefficient}</VoteInfo>
      ) : isDuelActive && prevDataRound?.winner !== undefined && localStorage.getItem('showLastIcon') === 'true' ? (
        <VoteInfo>Winner with coefficient of {prevDataRound?.coefficient}</VoteInfo>
      ) : (
        <></>
      )}
    </>
  )
}
