import { Routes, Route } from 'react-router-dom'

import { PublicRoute, PrivateRoute } from './'

import { BettingPage, SignIn, SignUp, ConfirmationPage, ForgotPassword, NewPassword } from '../pages'

export const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<PrivateRoute />}>
      <Route path='/' element={<BettingPage />} />
    </Route>

    <Route path='/' element={<PublicRoute />}>
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/confirm' element={<ConfirmationPage />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/forgot-password/:code' element={<NewPassword />} />
    </Route>
  </Routes>
)
