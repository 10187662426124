import { Formik, Form } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as LogoImage } from '../assets/logo.svg'
import { PageWrapper, Input, Button } from '../components'
import { useFetch } from '../hooks'

interface FormValues {
  email: string
}

const initialValues: FormValues = {
  email: '',
}

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email('Invalid email').required('Required'),
})

const FormStyled = styled(Form)`
  height: 100%;
  margin-top: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`

const LogoImageStyled = styled(LogoImage)`
  width: 120px;
  height: 28px;
  margin: 12px 12px 0 auto;
  flex-shrink: 0;
  flex-grow: 0;
`

export const ForgotPassword = () => {
  const { fetchData, isError } = useFetch<null>('/auth/reset-password')

  return (
    <PageWrapper>
      <LogoImageStyled />
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues) =>
          fetchData({
            body: values,
          })
        }
        validationSchema={validationSchema}
      >
        <FormStyled>
          <Input type='email' name='email' label='email' />
          <Button type='submit'>submit</Button>
        </FormStyled>
      </Formik>
    </PageWrapper>
  )
}
