import { useState } from 'react'
import { toast } from 'react-toastify'

interface FetchProps<T> {
  body?: object
  method?: string
  onSuccess?: (response: T) => void
  onFail?: () => void
}

export const useFetch = <T = any,>(url: string) => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [response, setResponse] = useState<T | undefined>(undefined)
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const fetchData = async ({ body, method, onSuccess, onFail }: FetchProps<T> = {}) => {
    setFetching(true)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: method || body ? 'POST' : 'GET',
        headers: { 'Content-Type': 'application/json' },
        body: body ? JSON.stringify(body) : undefined,
      })

      const responseBody = await response.json()

      if (!responseBody.success) {
        throw responseBody.message
      }

      setResponse(responseBody.data)
      onSuccess?.(responseBody.data)
      return responseBody.data
    } catch (error: any) {
      setIsError(true)
      typeof error === 'string' && setErrorMessage(error)
      toast.warning(typeof error === 'string' ? error : error[0])
      onFail?.()
    } finally {
      setFetching(false)
    }
  }

  return {
    fetchData,
    response,
    fetching,
    isError,
    errorMessage,
  }
}
