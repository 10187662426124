import { useEffect, useState } from 'react'
import { formatUnits } from 'viem'
import { useContractRead, useContractReads, useAccount } from 'wagmi'

import { duelsContract } from '../contracts'

interface DuelStatusResponse {
  startTime: number
  endTime: number
}

export type UserVoteResponse = number

export interface DuelState {
  duelStarted: number
  duelEnded: number
  isDuelActive: boolean
  timerEnd: Date
  currentDuelId: bigint | any
}

export interface DuelWinner {
  coefficient: number
  winner: number | undefined
}

export interface UseDuel extends DuelState {
  amountToBet: number
  setAmountToBet: React.Dispatch<React.SetStateAction<number>>
  votedAmount: [number, number]
  duelWinner: DuelWinner
  prevDataRound: DuelWinner
  getDuelStatus: () => Promise<void>
}

const contractReadOptions = {
  address: process.env.REACT_APP_DUELS_CONTRACT_ADDRESS as `0x${string}`,
  abi: duelsContract,
}

export const useDuel = () => {
  const [winnerState, setWinnerState] = useState<DuelWinner | undefined>({ coefficient: 0, winner: undefined })

  const [duelState, setDuelState] = useState<DuelState>({
    duelStarted: 0,
    duelEnded: 0,
    isDuelActive: false,
    timerEnd: new Date(),
    currentDuelId: 0n,
  })

  const { address } = useAccount()

  const { data: currentDuelId } = useContractRead({
    ...contractReadOptions,
    functionName: 'duelIdCounter',
    watch: true,
  })

  const { refetch: getPrevDuelFromContract } = useContractRead({
    ...contractReadOptions,
    functionName: 'duels',
    args: [currentDuelId ? (currentDuelId as bigint) - 1n : 1n],
    select: (data) =>
      ({
        startTime: parseInt(formatUnits(data[0], 0)),
        endTime: parseInt(formatUnits(data[1], 0)),
      } as DuelStatusResponse),
    enabled: false,
  })

  const { refetch: getDuelFromContract } = useContractRead({
    ...contractReadOptions,
    functionName: 'duels',
    args: [currentDuelId as bigint],
    select: (data) =>
      ({
        startTime: parseInt(formatUnits(data[0], 0)),
        endTime: parseInt(formatUnits(data[1], 0)),
      } as DuelStatusResponse),
    enabled: false,
  })

  const { data: amountForFirstOption } = useContractRead({
    ...contractReadOptions,
    functionName: 'userVotes',
    args: [address as `0x${string}`, currentDuelId as bigint, 0],
    select: (data) => parseFloat(formatUnits(data, 18)) as UserVoteResponse,
    watch: true,
    enabled: !!address,
  })

  const { data: amountForSecondOption } = useContractRead({
    ...contractReadOptions,
    functionName: 'userVotes',
    args: [address as `0x${string}`, currentDuelId as bigint, 1],
    select: (data) => parseFloat(formatUnits(data, 18)) as UserVoteResponse,
    watch: true,
    enabled: !!address,
  })

  const { data: duelWinner } = useContractReads({
    contracts: [
      {
        ...contractReadOptions,
        functionName: 'checkWinningNft',
        args: [currentDuelId ? (currentDuelId as bigint) - 1n : 1n],
      },
      {
        ...contractReadOptions,
        functionName: 'checkWinningNft',
        args: [currentDuelId as bigint],
      },
    ],
    select: (data) => {
      const prevDuelWinner = data[0].result as readonly [bigint, number]
      const currentDuelWinner = data[1].result as readonly [bigint, number]

      if (duelState.duelStarted * 1000 > Date.now()) {
        return {
          coefficient: +parseFloat(formatUnits(prevDuelWinner[0], 18)).toFixed(2),
          winner: !!prevDuelWinner[0] ? prevDuelWinner[1] : undefined /*|| undefined */,
        }
      } else {
        return {
          coefficient: +parseFloat(formatUnits(currentDuelWinner[0], 18)).toFixed(2),
          winner: !!currentDuelWinner[0] ? currentDuelWinner[1] : undefined /*|| undefined*/,
        }
      }
    },
    watch: !duelState.isDuelActive && !winnerState?.winner,
    enabled: !!address,
  })

  const { data: prevDataRound } = useContractReads({
    contracts: [
      {
        ...contractReadOptions,
        functionName: 'checkWinningNft',
        args: [currentDuelId ? (currentDuelId as bigint) - 1n : 1n],
      },
      {
        ...contractReadOptions,
        functionName: 'checkWinningNft',
        args: [currentDuelId as bigint],
      },
    ],
    select: (data) => {
      const prevDuelWinner = data[0].result as readonly [bigint, number]
      const currentDuelWinner = data[1].result as readonly [bigint, number]

      if (duelState.isDuelActive) {
        return {
          coefficient: +parseFloat(formatUnits(prevDuelWinner[0], 18)).toFixed(2),
          winner: !!prevDuelWinner[0] ? prevDuelWinner[1] : undefined /*|| undefined */,
        }
      }
    },
    watch: !duelState.isDuelActive && !winnerState?.winner,
    enabled: !!address,
  })

  useEffect(() => {
    if (currentDuelId?.toString() !== localStorage.getItem('lastShowedDuel')) {
      localStorage.setItem('lastShowedDuel', `${currentDuelId}`)
      localStorage.setItem('showLastIcon', `${true}`)
    }
    setWinnerState(!duelState.isDuelActive && winnerState?.winner !== undefined ? winnerState : duelWinner)
  }, [duelWinner, duelState])

  useEffect(() => {
    getDuelStatus()
  }, [])

  const getDuelStatus = async () => {
    const { data: prevDuelData } = await getPrevDuelFromContract()
    const { data: duelData } = await getDuelFromContract()

    if (!duelData || !prevDuelData) return

    const isDuelActive = Date.now() < duelData.endTime * 1000 && Date.now() > duelData.startTime * 1000

    const timerEnd = isDuelActive
      ? new Date(duelData.endTime * 1000)
      : Date.now() < duelData.startTime * 1000
      ? new Date(duelData.startTime * 1000)
      : new Date(duelData.endTime * 1000 + 30000)

    setDuelState((prev) => ({
      ...prev,
      duelStarted: duelData.startTime,
      duelEnded: duelData.endTime,
      isDuelActive,
      timerEnd,
      currentDuelId: currentDuelId || 0n,
    }))
  }

  return {
    ...duelState,
    duelWinner: winnerState,
    prevDataRound: prevDataRound,
    votedAmount: [amountForFirstOption, amountForSecondOption],
    getDuelStatus,
  } as UseDuel
}
