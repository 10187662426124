import styled from 'styled-components'

import { ButtonProps, ButtonStyledProps } from './types'

import { Loader } from '../loader'

const Wrapper = styled.div<ButtonStyledProps>`
  ${({ width }) => width && `max-width: ${width}px;`}
  font-size: 26px;
  width: 100%;
  margin: 0 auto;
`

const ButtonBorder = styled.div`
  display: inline-block;
  width: 100%;
  background: linear-gradient(90deg, #ffffff 0%, #fff367 58.46%, #ff9705 100%);
  border-radius: 12px;
  padding: 1px;
  cursor: pointer;
`

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 5px 25px;
  border: none;
  border-radius: 12px;
  background: #1f1f1f;

  font-size: inherit;
  color: #fff;
  text-transform: capitalize;

  cursor: inherit;

  &:disabled {
    background: #1f1f1f;
    opacity: 0.75;
    cursor: not-allowed;
  }
`

export const Button = ({ children, className, width, isLoading, ...rest }: ButtonProps) => {
  return (
    <Wrapper className={className} width={width}>
      <ButtonBorder>
        <StyledButton {...rest} isLoading>
          {isLoading ? <Loader /> : children}
        </StyledButton>
      </ButtonBorder>
    </Wrapper>
  )
}
