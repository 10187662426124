import { useState } from 'react'
import styled from 'styled-components'

import { useBetContext } from '../../context'
import { PREV_OPTIONS } from '../bettingOptions'

const WrapperForRound = styled.div`
  position: absolute !important;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000 27.08%, rgba(255, 255, 255, 0) 100%);
  top: 0;
  left: 0;
  display: flex;
  // align-items: center;
  // flex-direction: column;
  padding: 20px;
  z-index: 10;
`
const Image = styled.img`
  display: block;
  /* width: 185px; */
  width: 165px;
  height: 182px;
  object-fit: contain;
  /* border-radius: 18px; */
  margin: 0 auto;
  transform-origin: top center;
  position: relative;
  top: 61px;
  transform: scale(1.85);
  z-index: 11;
`

export const WrapperForPrevRound = () => {
  const { isDuelActive, prevDataRound } = useBetContext()

  return localStorage.getItem('showLastIcon') === 'true' && prevDataRound?.winner !== undefined && isDuelActive ? (
    <WrapperForRound>
      {<Image src={PREV_OPTIONS[prevDataRound?.winner].src} alt='prev winning picture' />}
    </WrapperForRound>
  ) : null
}
