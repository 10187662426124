import { toast } from 'react-toastify'
import { formatUnits } from 'viem'
import { useContractRead, useAccount, usePrepareContractWrite, useContractWrite } from 'wagmi'

import { HashLink } from '../components'
import { duelsContract } from '../contracts'

const oldContractReadOptions = {
  address: process.env.REACT_APP_OLD_DUELS_CONTRACT_ADDRESS as `0x${string}`,
  abi: duelsContract,
}

const contractReadOptions = {
  address: process.env.REACT_APP_DUELS_CONTRACT_ADDRESS as `0x${string}`,
  abi: duelsContract,
}

export const useClaim = () => {
  const { address } = useAccount()

  const { data: oldAvailableToClaim } = useContractRead({
    ...oldContractReadOptions,
    account: address,
    functionName: 'availableToClaim',
    args: [address as `0x${string}`],
    select: (data) => {
      const parsedResult = parseFloat(formatUnits(data, 18))
      return parsedResult && parsedResult.toFixed(4)
    },
    watch: true,
  })

  const { data: availableToClaim } = useContractRead({
    ...contractReadOptions,
    account: address,
    functionName: 'availableToClaim',
    args: [address as `0x${string}`],
    select: (data) => {
      const parsedResult = parseFloat(formatUnits(data, 18))
      return parsedResult && parsedResult.toFixed(4)
    },
    watch: true,
  })

  const { config: oldClaimConfig } = usePrepareContractWrite({
    ...oldContractReadOptions,
    abi: duelsContract,
    functionName: 'claim',
    enabled: !!oldAvailableToClaim,
  })

  const { isLoading: oldIsClaimLoading, writeAsync: oldMakeClaim } = useContractWrite(oldClaimConfig)

  const { config: claimConfig } = usePrepareContractWrite({
    ...contractReadOptions,
    abi: duelsContract,
    functionName: 'claim',
    enabled: !!availableToClaim,
  })

  const { isLoading: isClaimLoading, writeAsync: makeClaim } = useContractWrite(claimConfig)

  const oldClaim = async () => {
    try {
      const claimResponse = await oldMakeClaim?.()

      if (claimResponse?.hash)
        toast.success(
          <span>
            Tokens successfuly claimed <HashLink hash={claimResponse.hash} />
          </span>,
        )
    } catch (error: any) {
      toast.warning(typeof error === 'string' ? error : error.shortMessage)
    }
  }

  const claim = async () => {
    try {
      const claimResponse = await makeClaim?.()

      if (claimResponse?.hash)
        toast.success(
          <span>
            Tokens successfuly claimed <HashLink hash={claimResponse.hash} />
          </span>,
        )
    } catch (error: any) {
      toast.warning(typeof error === 'string' ? error : error.shortMessage)
    }
  }

  return {
    availableToClaim: oldAvailableToClaim || availableToClaim,
    isClaimLoading: oldAvailableToClaim ? oldIsClaimLoading : isClaimLoading,
    claim: oldAvailableToClaim ? oldClaim : claim,
  }
}
