// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__rBVsr {
  position: absolute !important;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #000 27.08%, rgba(255, 255, 255, 0) 100%);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  z-index: 1000; }

.style_modal__B6Nhe {
  border-radius: 12px;
  background-color: black; }
  .style_modal__gradient__S-jBF {
    border-radius: 12px;
    background: linear-gradient(100deg, black 0%, rgba(255, 255, 255, 0.2) 30%, black 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px; }
  .style_modal__title__3HmXU {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    text-align: center;
    margin: 0; }
  .style_modal__text__cZQh4 {
    color: white;
    text-align: center;
    font-size: 14px; }
  .style_modal__button__bsokm {
    width: auto; }
`, "",{"version":3,"sources":["webpack://./src/components/refundModal/style.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,6EAA6E;EAC7E,MAAM;EACN,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,aAAa,EAAA;;AAGf;EACE,mBAAmB;EACnB,uBAAuB,EAAA;EAEvB;IACE,mBAAmB;IACnB,uFAA6G;IAC7G,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa,EAAA;EAGf;IACE,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,SAAS,EAAA;EAGX;IACE,YAAY;IACZ,kBAAkB;IAClB,eAAe,EAAA;EAGjB;IACE,WAAW,EAAA","sourcesContent":[".wrapper {\n  position: absolute !important;\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(180deg, #000 27.08%, rgba(255, 255, 255, 0) 100%);\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  padding: 20px;\n  z-index: 1000;\n}\n\n.modal {\n  border-radius: 12px;\n  background-color: black;\n\n  &__gradient {\n    border-radius: 12px;\n    background: linear-gradient(100deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.2) 30%, rgba(0, 0, 0, 1) 100%);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    padding: 20px;\n  }\n\n  &__title {\n    font-size: 20px;\n    font-weight: 600;\n    text-transform: uppercase;\n    color: white;\n    text-align: center;\n    margin: 0;\n  }\n\n  &__text {\n    color: white;\n    text-align: center;\n    font-size: 14px;\n  }\n\n  &__button {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__rBVsr`,
	"modal": `style_modal__B6Nhe`,
	"modal__gradient": `style_modal__gradient__S-jBF`,
	"modal__title": `style_modal__title__3HmXU`,
	"modal__text": `style_modal__text__cZQh4`,
	"modal__button": `style_modal__button__bsokm`
};
export default ___CSS_LOADER_EXPORT___;
